$(document).ready(function () {
    var si = 1;
    var no = 2;

    $('select[name="uso_id"]').on("change", function () {
        var otros = 7;
        var val = $(this).val();
        if (val == otros) {
            $('#cont-especificar_otras').removeClass('d-none');
            $('#especificar_otras').prop('required', true);
        } else {
            $('#cont-especificar_otras').addClass('d-none');
            $('#especificar_otras').prop('required', false);
        }
    });

    $('input[name="conoce_construccion"]').on("change", function () {
        var val = $(this).val();
        if (val == si) {
            $('#cont-construccion').removeClass('d-none');
            $('#cont-fecha_construccion').removeClass('d-none');
            $('#fecha_construccion').prop('required', true);
            $('#area_construccion').prop('required', true);
            $('#cont-periodo_construccion').addClass('d-none');
            $('#periodo_construccion').prop('required', false);
            $('#periodo_construccion').val('');
        } else {
            $('#cont-construccion').removeClass('d-none');
            $('#cont-periodo_construccion').removeClass('d-none');
            $('#periodo_construccion').prop('required', true);
            $('#area_construccion').prop('required', true);
            $('#cont-fecha_construccion').addClass('d-none');
            $('#fecha_construccion').prop('required', false);
            $('#fecha_construccion').val('');
        }
    });

    $('input[name="tiene_ampliaciones"]').on("change", function () {
        var val = $(this).val();
        if (val == si) {
            $('#cont-tiene_ampliaciones').removeClass('d-none');
            $('#conoce_ampliacion').prop('required', true);
        } else {
            $('#cont-tiene_ampliaciones').addClass('d-none');
            $('#conoce_ampliacion').prop('required', false);
            $('input[name="conoce_ampliacion"]').prop('checked', false);
            $('#conoce_ampliacion').prop('checked', false);
            $('#cont-ampliacion').addClass('d-none');
            $('#fecha_ampliacion').val('');
            $('#fecha_ampliacion').prop('required', false);
            $('#periodo_ampliacion').val('');
            $('#periodo_ampliacion').prop('required', false);
            $('#area_ampliacion').val('');
            $('#area_ampliacion').prop('required', false);
        }
    });

    $('input[name="conoce_ampliacion"]').on("change", function () {
        var val = $(this).val();
        if (val == si) {
            $('#cont-ampliacion').removeClass('d-none');
            $('#cont-fecha_ampliacion').removeClass('d-none');
            $('#fecha_ampliacion').prop('required', true);
            $('#area_ampliacion').prop('required', true);
            $('#cont-periodo_ampliacion').addClass('d-none');
            $('#periodo_ampliacion').prop('required', false);
            $('#periodo_ampliacion').val('');
        } else {
            $('#cont-ampliacion').removeClass('d-none');
            $('#cont-periodo_ampliacion').removeClass('d-none');
            $('#periodo_ampliacion').prop('required', true);
            $('#area_ampliacion').prop('required', true);
            $('#cont-fecha_ampliacion').addClass('d-none');
            $('#fecha_ampliacion').prop('required', false);
            $('#fecha_ampliacion').val('');
        }
    });
});
