$(document).ready(function () {
    // Rehabilitación: Ninguna
    $('#rehabilitacion15').change(function () {
        var input = $(this);
        if (input.prop('checked')) {
            $('#rehabilitacion_ano15').val('NA');
        } else {
            $('#rehabilitacion_ano15').val('');
        }
    });
    // Recimentación: Ninguno
    $('#recimentacion5').change(function () {
        var input = $(this);
        if (input.prop('checked')) {
            $('#recimentacion_anos5').val('NA');
        } else {
            $('#recimentacion_anos5').val('');
        }
    });
});
