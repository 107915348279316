$(document).ready(function () {
    $('body').on('change', 'input:checkbox', function () {
        var input = $(this);
        var name = this.name;
        var contenedor = $('#div_' + name);
        var valor = $('#' + name + '_valor');
        var valor_permisible = $('#' + name + '_valor_permisible');
        var texto = $('#div_' + name + ' :input');
        if (input.prop('checked')) {
            contenedor.removeClass('d-none');
            //texto.prop('required', true);
        } else {
            contenedor.addClass('d-none');
            valor.val('');
            valor_permisible.val('');
            //texto.prop('required', false);
        }
    });

    window.onload = function () {
        $('input[name="separacion_colindancias"]').on("click", function () {
            var val = $('#' + this.id).val();
            if (val == 1 && val != null) {
                console.log('si');
                $('#div_separacion_colindancias').removeClass('d-none');
                $('input[name="valor_direccion"]').prop('required', true);
                $('input[name="valor_permisible"]').prop('required', true);
            } else {
                console.log('no');
                $('#div_separacion_colindancias').addClass('d-none');
                $('input[name="valor_direccion"]').val('');
                $('input[name="valor_direccion"]').prop('required', false);
                $('input[name="valor_permisible"]').val('');
                $('input[name="valor_permisible"]').prop('required', false);
            }
        });
    };

    $('body').on('click', 'input:radio', function () {
        var input = $(this);
        var val = $('#' + this.id).val();
        var name = this.name;
        var contenedor = $('#div_' + name);
        var valor = $('#' + name + '_valor');
        var texto = $('#div_' + name + ' :input');

        if (val == 1 && val != null) {
            console.log('si');
            contenedor.removeClass('d-none');
        } else if (val == 0 && val != null) {
            console.log('no');
            contenedor.addClass('d-none');
            valor.val('');
        }
    });
});