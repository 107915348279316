$(document).ready(function () {
    $('#table_relacion').on('click', 'input:checkbox', function () {
        contador = $('[name="plantel[]"]:checked').length
        usuario = $('[name="usuario"]').val()
        if (contador !== 0) {
            $('#elegir_accion').prop('disabled', false);
        } else {
            $('#elegir_accion').prop('disabled', true);
        }
    });
    $('#elegir_accion').on('change', function () {
        accion = $('[name="elegir_accion"]').val()
        if (accion == 1) {
            $('#cont_asignar').removeClass('d-none');
            $('#asignar').removeClass('desactivado');
            $('#cont_descargar').addClass('d-none');
            $('#descargar').addClass('desactivado');
            $('#usuario').prop('disabled', false);
            $('#usuario').prop('required', true);
        } else if (accion == 2) {
            $('#cont_asignar').addClass('d-none');
            $('#asignar').addClass('desactivado');
            $('#cont_descargar').removeClass('d-none');
            $('#descargar').removeClass('desactivado');
            $('#usuario').prop('disabled', true);
            $('#usuario').prop('required', false);
        } else {
            $('#cont_descargar').addClass('d-none');
            $('#descargar').addClass('desactivado');
            $('#cont_asignar').addClass('d-none');
            $('#asignar').addClass('desactivado');
            $('#usuario').prop('disabled', true);
            $('#usuario').prop('required', false);
        }
    });
});