$(document).ready(function () {
    //Números como telefónicos o campos exclusivamente llenados con números enteros
    $(".number").on('input keydown paste', function () {
        var inputText = $(this).val();
        var resultText = inputText.replace(/[^0-9]/g, '');
        $(this).val(resultText);
    });
    //Números con decimales, permite comas y puntos
    $(".decimal").bind("keypress", function (event) {
        if (event.charCode != 0) {
            var regex = new RegExp("^[0-9.]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    });
});