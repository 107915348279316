$(document).ready(function () {

    // Mediante el CP obtenemos la alcaldía y colonia
    $('.cp-change').keyup(function () {
        var len = $(this).val().length;
        var url = $('#site_url').val();
        var valor = $(this).val();
        if (len >= 5) {
            $.ajax({
                type: 'GET',
                url: url + "/cp?q=" + valor,
                success: function (data) {
                    if (data.alcaldia != '') {
                        $("#colonia_id option[value='" + data.colonia + "']").attr("selected", true);
                        $("#alcaldia_id option[value='" + data.alcaldia + "']").attr("selected", true);
                    }
                },
                error: function () {
                    console.log('error');
                }
            });
        }
    });

    var map;
    var geocoder;

    if ($("#map_canvas").length) {

        function initialize(id_mapa) {
            geocoder = new google.maps.Geocoder();
            var latlng = new google.maps.LatLng(base_latitud, base_longitud);
            var mapOptions = {
                zoom: 16,
                center: latlng
            }
            map = new google.maps.Map(document.getElementById(id_mapa), mapOptions);
        }

        function localizar(elemento, direccion) {
            var geocoder = new google.maps.Geocoder();
            var map = new google.maps.Map(document.getElementById(elemento), {
                zoom: 16,
                //scrollwheel: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            });

            geocoder.geocode({ 'address': direccion }, function (results, status) {
                if (status === 'OK') {
                    var resultados = results[0].geometry.location,
                        resultados_lat = resultados.lat(),
                        resultados_long = resultados.lng();
                    map.setCenter(results[0].geometry.location);
                    var marker = new google.maps.Marker({
                        map: map,
                        draggable: true,
                        position: results[0].geometry.location
                    });
                    google.maps.event.addListener(marker, 'dragend', function (event) {
                        document.getElementById("latitud").value = this.position.lat();
                        document.getElementById("longitud").value = this.position.lng();
                        geocodeLatLng(this.position.lat(), this.position.lng());
                    });
                } else {
                    var mensajeError = "";
                    if (status === "ZERO_RESULTS") {
                        mensajeError = "No hubo resultados para la dirección ingresada.";
                    } else if (status === "OVER_QUERY_LIMIT" || status === "REQUEST_DENIED" || status === "UNKNOWN_ERROR") {
                        mensajeError = "Error general del mapa.";
                    } else if (status === "INVALID_REQUEST") {
                        mensajeError = "Error de la web. Contacte con el administrador del sistema.";
                    }
                    alert(mensajeError);
                }
                document.getElementById("latitud").value = resultados_lat;
                document.getElementById("longitud").value = resultados_long;
            });
        }

        // API Maps: Local
        // $.getScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBK-oOZXUTBchzW4kExFU6GYmUzD0TlDQk", function () {
        
        // API Maps: PROD
        $.getScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyACqtlSVZ6NLejIOuPvLTfvPtQU-xa9AUQ", function () {
            initialize('map_canvas');
            $("#cp").change(function () {
                var calle = $("#calle").val();
                var cp = $("#cp").val();
                var num_ext = $("#no_exterior").val();
                var direccion = calle + " " + num_ext + ", " + cp + " Ciudad de México, CDMX";
                console.log(direccion)
                if (direccion !== "") {
                    localizar("map_canvas", direccion);
                }
            });
            $("#actualizar_mapa").click(function () {
                var latitud = $("#latitud").val();
                var longitud = $("#longitud").val();
                var direccion = latitud + " " + longitud;
                if (direccion !== "") {
                    localizar("map_canvas", direccion);
                }
            });
        });
        //funciones asociadas al evento dragend
        // function geocodeLatLng(latitude, longitude) {
        //   //console.log('decode adres');
        //   geocoder = new google.maps.Geocoder();
        //   var latlng = {lat: latitude, lng: longitude};
        //   geocoder.geocode({'location': latlng}, function(results, status) {
        //     if (status === 'OK') {
        //       if (results[0]) {
        //         console.log(results[0].address_components);
        //         setAddress(results[0].address_components);
        //         //updateMrketPosition(latitude,longitude);
        //       } else {
        //         window.alert('No results found');
        //       }
        //     } else {
        //       window.alert('Geocoder failed due to: ' + status);
        //     }
        //   });
        // }
        //
        // function setAddress(address_components){
        //  // console.log('dirección');
        //   for(var i=0; i<address_components.length; i++){
        //    // console.log(address_components[i].types[0]+'.....'+address_components[i].long_name);
        //     switch(address_components[i].types[0]) {
        //       case "street_number":
        //         $('#numero_exterior').val(address_components[i].long_name);
        //         // console.log('numero exterior: '+address_components[i].long_name);
        //         break;
        //       case "route":
        //         $('#calle').val(address_components[i].long_name);
        //         // console.log('calle: '+address_components[i].long_name);
        //         break;
        //       case "postal_code":
        //         $('#cp').val(address_components[i].long_name);
        //         // console.log('cp: '+address_components[i].long_name);
        //         break;
        //       case "political":
        //         $('#colonia').val(address_components[i].long_name);
        //         // console.log('colonia: '+address_components[i].long_name);
        //         //$scope.buscaColoniasUbicacion($scope.folio.codigo_postal_solicitud)
        //         break;
        //     }
        //   }
        // }
    }
});
