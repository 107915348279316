$(document).ready(function () {
  var fecha = new Date();
  var minDate = 1000;
  var maxDate = fecha.getFullYear();
  $('.fecha_construccion, .fecha_ampliacion').change(function () {
    var inputDate = $(this).val();
    if (inputDate > maxDate) {
      $(this).val(maxDate);
    } else if (inputDate < minDate) {
      $(this).val(minDate);
    }
  });
});