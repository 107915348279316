$(document).ready(function () {
  $('body').on('change', 'input:file', function () {
    console.log(this);
    var input = document.getElementById(this.id);
    var fileName = input.files[0].name;
    var input_id = this.id;
    var label = $("label[for=" + input_id + "]");
    $('#borrar-' + input_id).removeClass('d-none');
    $('#guardar-' + input_id).removeClass('d-none');
    label.html(fileName);
  });

  $('#borrar-ubicacion_cuerpos').on("click", function () {
    $('#borrar-ubicacion_cuerpos').addClass('d-none');
    document.getElementById("ubicacion_cuerpos").value = "";
    $('label[for="ubicacion_cuerpos"]').html('Selecciona un archivo');
    return false;
  });

  $('#borrar-croquis_plantas_elevaciones').on("click", function () {
    $('#borrar-croquis_plantas_elevaciones').addClass('d-none');
    $('#guardar-croquis_plantas_elevaciones').addClass('d-none');
    document.getElementById("croquis_plantas_elevaciones").value = "";
    $('label[for="croquis_plantas_elevaciones"]').html('Selecciona un archivo');
    return false;
  });

  $('#borrar-fachadas_plantel').on("click", function () {
    $('#borrar-fachadas_plantel').addClass('d-none');
    $('#guardar-fachadas_plantel').addClass('d-none');
    document.getElementById("fachadas_plantel").value = "";
    $('label[for="fachadas_plantel"]').html('Selecciona un archivo');
    return false;
  });

  $('#borrar-interiores_de_cuerpo').on("click", function () {
    $('#borrar-interiores_de_cuerpo').addClass('d-none');
    $('#guardar-interiores_de_cuerpo').addClass('d-none');
    document.getElementById("interiores_de_cuerpo").value = "";
    $('label[for="interiores_de_cuerpo"]').html('Selecciona un archivo');
    return false;
  });

  $('#borrar-cimentacion_cuerpo').on("click", function () {
    $('#borrar-cimentacion_cuerpo').addClass('d-none');
    $('#guardar-cimentacion_cuerpo').addClass('d-none');
    document.getElementById("cimentacion_cuerpo").value = "";
    $('label[for="cimentacion_cuerpo"]').html('Selecciona un archivo');
    return false;
  });

  $('#borrar-nivel_azotea').on("click", function () {
    $('#borrar-nivel_azotea').addClass('d-none');
    $('#guardar-nivel_azotea').addClass('d-none');
    document.getElementById("nivel_azotea").value = "";
    $('label[for="nivel_azotea"]').html('Selecciona un archivo');
    return false;
  });

});



