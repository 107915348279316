$(document).ready(function(){

  fixedFooter();

  $( window ).scroll(function() {
    fixedFooter();
  });

  function fixedFooter(){
    var height = $(window).height();
    console.log(height);
    var heightBody = $('body').height() + 150;

    if(height > heightBody){
      $('footer').addClass('fixed');
    }
  }
});
